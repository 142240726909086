@import '../../assets/styles/settings/*.css';

:root {
    --navbar--height: 100px;
    --navbar--small-height: 80px;
}

.navbar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: var(--navbar--height);
    transition: all 0.5s;
    &--inverted {
        height: var(--navbar--small-height);
        background-color: var(--background-color);
        z-index: 5;
        border-bottom: 1px solid var(--active-color)
    }
}

.navbar__logo {
    height: var(--navbar--height)
}
.navbar--inverted .navbar__logo {
    height: var(--navbar--small-height);
}

.navbar__collapse-container {
    height: var(--navbar--height);
    padding-top: 27px;
    padding-bottom: 27px;
    text-align: right;
}
.navbar--inverted .navbar__collapse-container {
    height: var(--navbar--small-height);
    padding-top: 17px;
    padding-bottom: 17px;
}
.navbar__menu-container {
    text-align: right;
}
.navbar__menu {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    display: inline-block;
    list-style: none;
    &__item {
        display: block;
        float: left;
    }
}
.navbar--inverted .navbar__menu {
    margin-top: 10px;
}
.navbar__menu__item__link {
    color: var(--text-color-inverted);
    display: block;
    line-height: 60px;
    padding: 0 10px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    white-space: nowrap;

    &:hover, &.navbar__menu__item__link--active {
        color: var(--text-color-inverted);
        text-decoration: none;
        border-bottom: 1px solid var(--text-color-inverted);
    }
}
.navbar--inverted .navbar__menu__item__link {
    color: var(--active-color-dark);

    &:hover, &.navbar__menu__item__link--active {
        color: var(--active-color);
        border-bottom: 1px solid var(--active-color);
    }
}
@media (--sm-d-viewport) {
    .navbar__menu-container {
        height: 100%; /* 100% Full-height */
        width: 0; /* 0 width - change this with JavaScript */
        padding: 0;
        position: fixed;
        top: 0;
        right: 0;
        overflow-x: hidden;
        transition: 0.5s;
        background-color: var(--background-color);
        border-left: 1px solid var(--active-color-dark);
        box-shadow: -5px 0 10px -7px #000;
        &.navbar__menu-container--shown {
            width: 60%;
            max-width: 250px;
        }
    }
    .navbar__menu {
        display: block;
        margin: 0;
        &__item {
            display: block;
            float: none;
            text-align: center;

            &__link {
                display: block;
                color: var(--active-color);
                &:hover, &.navbar__menu__item__link--active {
                    color: var(--active-color-dark);
                    border-bottom: 1px solid var(--active-color-dark);
                }
            }
        }
    }
}
