@import '../../../assets/styles/settings/*';

.page-header {
    padding-top: 100px;
    min-height: 300px;
    color: var(--text-color-inverted);
    &__background {
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 300px;
        @media (orientation: landscape) {
            height: calc( 100vw * 0.18 )
        }
    }

    &__title {
        text-align: center;
        font-size: 4em;
        text-transform: capitalize;
        color: var(--text-color-inverted);
        text-shadow:
            -2px -2px 0 var(--text-color),
            2px -2px 0 var(--text-color),
            -2px 2px 0 var(--text-color),
            2px 2px 0 var(--text-color);
        @media (--sm-d-viewport) {
            font-size: 3em;
        }
    }
}

