@import '../../assets/styles/settings/*.css';

.page-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    line-height: 100vh;
    z-index: 100;
    background-color: var(--background-color);
    color: var(--active-color);
    text-align: center;
    vertical-align: middle;
}
