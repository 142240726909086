@import '../../assets/styles/settings/*.css';

.button {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    &:hover {
        text-decoration: none;
    }
    &--block {
        display: block;
        margin-bottom: 10px;
    }
}

.button--active {
    color: var(--active-color);
    border: 1px solid var(--active-color);
    &:hover {
        color: var(--active-color-dark);
        border: 1px solid var(--active-color-dark);
    }
    &.button--solid {
        color: var(--text-color-inverted);
        background-color: var(--active-color);
        &:hover {
            color: var(--text-color-inverted);
            background-color: var(--active-color-dark);
        }
    }
    &.button--hover-solid:hover {
        color: var(--text-color-inverted);
        background-color: var(--active-color);
    }
}

.button--inverted {
    color: var(--text-color-inverted);
    border: 1px solid var(--text-color-inverted);
    &:hover {
        color: var(--active-color-inverted);
        border: 1px solid var(--active-color-inverted);
    }
    &.button--solid {
        color: var(--active-color);
        border: 1px solid var(--active-color);
        background-color: var(--text-color-inverted);
        &:hover {
            color: var(--active-color-dark);
            border: 1px solid var(--active-color-dark);
            background-color: var(--active-color-inverted);
        }
    }
}

.buttom--block {
    display: block;
    margin-bottom: 15px;
}
